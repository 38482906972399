import React, { useState, useEffect, useContext, useRef } from 'react';
import AffidavitGenerator from './AffidavitGenerator';
import { getParticipantsData } from './affidavitData';
import styles from './styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AppContext } from "../AppContext"

function AffidavitStatusChecker({ merchant, selectedDate }) {
    const [participants, setParticipants] = useState([]);
    
    const {editMerchant} = useContext(AppContext)
    const editRef = useRef(editMerchant)

    const activeSt = merchant.status ? "completed" : "notStarted";
    const guarantorTwo = merchant.second_guarantor_title_case;

    useEffect(() => {
        if (Object.keys(merchant).length > 0) {
            setParticipants(getParticipantsData(merchant, guarantorTwo));
        }
    }, [merchant, guarantorTwo]);

    useEffect(() => {
        const fetchParticipantData = async () => {
            if (!merchant.status && participants.length > 0) {
                const results = await Promise.all(
                    participants.map(async (par) => {
                        const response = await fetch('/get-mail-tracking-status', {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({ pic: par.pic })
                        });
                        const data = await response.json();
                        return data.Data[0].AcceptanceDoc;
                    })
                );
                const allDataFilled = results.every(doc => doc);
                if (allDataFilled) {
                    await fetch(`/merchants/${merchant.id}`, {
                        method: 'PATCH',
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ status: true })
                    })
                    .then(response => response.json())
                    .then(data => editRef.current(data));
                }
            } 
        }
        fetchParticipantData();
    }, [merchant, participants]);
    
    if (Object.keys(merchant).length < 1) return <div></div>

    return (
        <Box sx={styles.container}>
            <AffidavitGenerator merchant={merchant} selectedDate={selectedDate} />
            <Typography sx={{...styles.st, ...styles[activeSt]}}>
                {merchant.status ? 'Post Office Received' : 'AOS Not Started'}
            </Typography>
        </Box>
    );
}
export default AffidavitStatusChecker;